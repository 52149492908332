import React, { useState } from 'react'
import Index from '../../../container/Index'
import PagesIndex from '../../../container/PagesIndex'
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';


export default function Footer() {
  const [loading, setLoading] = useState(false)

  const initialValues = {
    name: "",
    email: "",
  }
  const handleClickMenuItem = (id) => {
    var element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }


  const sendEmail = async (templateParams) => {
    try {
      const serviceID = 'service_08zdbll';
      const templateID = 'template_11mxoyg';
      const userID = 'qp9grQLDX8afw3Kfm';

      const response = await emailjs.send(serviceID, templateID, templateParams, userID);
      return response;
    } catch (error) {
      console.error('Failed to send email:', error);
      throw error;
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)
    sendEmail({
      to: values.email,
      to_name: `Hi ${values.name}`,
      message: `You have subscribed to the news letter. Please check below link,`,
      link: "https://aurum.appworkdemo.com",
    })
      .then((response) => {
        setLoading(false)
        toast.success('You are subscribed to the newsletter successfully.')
        console.log("Email sent successfully:", response);
        resetForm();
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        setLoading(false)
      });
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={PagesIndex.validateUser}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <PagesIndex.Form onSubmit={handleSubmit}>
          <Toaster
            reverseOrder={false}
            toastOptions={{
              duration: 3000,
              style: {
                maxWidth: "unset",
                color: "black",
                fontWeight: 700,
              },
            }}
          />
          <Index.Box className="footer">
            <Index.Box className="container">
              <Index.Box className='footer-row'>
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 5, lg: 5 }}>
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 7", lg: "span 7" }} className="grid-column">
                      <Index.Box className="footer-col">
                        {/* <Index.Typography className='footer-titles'>AURUM</Index.Typography> */}
                        <img src={PagesIndex.Svg.footerTokenImg} className='footer-token-img' />
                        <Index.Box className="footer-nav-main">
                          <Index.List className='footer-nav-ul'>
                            <Index.ListItem className='footer-nav-li'>
                              <Index.Link className='footer-nav-link' onClick={() => {
                                handleClickMenuItem("home-hero-main")
                              }}>Home</Index.Link>
                            </Index.ListItem>
                            <Index.ListItem className='footer-nav-li'>
                              <Index.Link className='footer-nav-link' onClick={() => {
                                handleClickMenuItem("about-us-main")
                              }}>About Us</Index.Link>
                            </Index.ListItem>
                            <Index.ListItem className='footer-nav-li'>
                              <Index.Link className='footer-nav-link' onClick={() => {
                                handleClickMenuItem("tokenomics-main")
                              }}>Tokenomics</Index.Link>
                            </Index.ListItem>
                            <Index.ListItem className='footer-nav-li'>
                              <Index.Link className='footer-nav-link' onClick={() => {
                                handleClickMenuItem("roadmap-main")
                              }}>Roadmap</Index.Link>
                            </Index.ListItem>
                            {/* <Index.ListItem className='footer-nav-li'>
                              <Index.Link className='footer-nav-link' onClick={() => {
                                handleClickMenuItem("team-main")
                              }}>Our Team</Index.Link>
                            </Index.ListItem> */}
                            <Index.ListItem className='footer-nav-li'>
                              <Index.Link className='footer-nav-link' onClick={() => {
                                handleClickMenuItem("faq-main")
                              }}>FAQ</Index.Link>
                            </Index.ListItem>
                          </Index.List>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 5", lg: "span 5" }} className="grid-column">
                      <Index.Box className="footer-col">
                        <Index.Box className="footer-form-main">
                          <Index.Typography className='footer-form-titles'>Don't Miss Out
                          </Index.Typography>
                          <Index.Typography className='footer-para'>Subscribe to receive the latest updates on market trends.
                          </Index.Typography>
                          <Index.Box className="footer-content-main">
                            <Index.Box className="footer-form-flex">
                              <Index.Box className="user-input-box footer-input-box">
                                <Index.Box className="user-form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="user-form-control"
                                    placeholder="Your Name"
                                    onChange={handleChange}
                                    name="name"
                                    value={values.name}
                                    error={errors?.name && touched?.name}
                                    inputProps={{ maxLength: 50 }}
                                  />
                                  <Index.FormHelperText className="user-error-text">
                                    {errors?.name && touched?.name ? errors?.name : null}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="user-input-box footer-input-box">
                                <Index.Box className="user-form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="email"
                                    className="user-form-control"
                                    placeholder="Your Email"
                                    onChange={handleChange}
                                    value={values.email}
                                    error={errors?.email && touched?.email}
                                    inputProps={{ maxLength: 50 }}
                                  />
                                  <Index.FormHelperText className="user-error-text">
                                    {errors?.email && touched?.email ? errors?.email : null}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Button type='submit' className='footer-btn'
                              disabled={loading}
                            >Subscribe</Index.Button>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="footer-social-main">
                          <Index.Typography className='footer-social-titles'>Follow Us</Index.Typography>
                          <Index.List className='footer-social-nav-ul'>
                            <Index.ListItem className='footer-social-nav-li'>
                              <a className='footer-social-link' href='https://x.com' target='_blank'>
                                <img src={PagesIndex.Svg.twitterIcon} alt='facebook' className='social-icon' />
                              </a>
                            </Index.ListItem>
                            <Index.ListItem className='footer-social-nav-li'>
                              <a className='footer-social-link' href='https://telegram.org/' target='_blank'>
                                <img src={PagesIndex.Svg.telegramIcon} alt='facebook' className='social-icon' />
                              </a>
                            </Index.ListItem>
                            <Index.ListItem className='footer-social-nav-li'>
                              <a className='footer-social-link' href='https://discord.com/' target='_blank'>
                                <img src={PagesIndex.Svg.discordIcon} alt='facebook' className='social-icon' />
                              </a>
                            </Index.ListItem>
                          </Index.List>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="container">
              <Index.Box className="footer-copy-main-flex">
                <Index.Box className="footer-copy-main">
                  <Index.Typography className='footer-copy-right-text'>© Copyright Aurum 2025 </Index.Typography>
                </Index.Box>
                {/* <Index.List className='footer-copy-nav'>
                  <Index.ListItem className='footer-copy-nav-li'>
                    <Index.Link className='footer-copy-nav-link'>
                      Data Privacy</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='footer-copy-nav-li'>
                    <Index.Link className='footer-copy-nav-link'>
                      Cookie Preferences</Index.Link>
                  </Index.ListItem>
                </Index.List> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </PagesIndex.Form>
      )}
    </PagesIndex.Formik>
  )
}
