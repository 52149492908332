import React, { useEffect, useRef } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import { useOutletContext } from "react-router-dom";


export default function Home() {

  const [currentTab, setCurrentTab] = useOutletContext();

  const sectionsRefs = {
    ["home-hero-main"]: useRef(null),
    ["about-us-main"]: useRef(null),
    ["tokenomics-main"]: useRef(null),
    ["roadmap-main"]: useRef(null),
    ["faq-main"]: useRef(null),
  };


  const handleScroll = () => {
    // setCurrentTab('')
    for (const key in sectionsRefs) {
      const sectionRef = sectionsRefs[key];
      if (sectionRef.current) {
        const targetTop = sectionRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        const threshold = windowHeight * 0.8;
        if (targetTop < threshold) {
          if (window.screen.width > 991) {
            setCurrentTab(key);
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Index.Box component={"section"} className="home-hero-main" id="home-hero-main" ref={sectionsRefs["home-hero-main"]}>
        <PagesIndex.HeroSection />
      </Index.Box>
      <Index.Box component={"section"} className="about-us-main" id="about-us-main" ref={sectionsRefs["about-us-main"]}>
        <PagesIndex.AboutUs />
      </Index.Box>
      <Index.Box component={"section"} className="tokenomics-main" id="tokenomics-main" ref={sectionsRefs["tokenomics-main"]}>
        <PagesIndex.Tokenomics />
      </Index.Box>
      <Index.Box component={"section"} className="roadmap-main" id="roadmap-main" ref={sectionsRefs["roadmap-main"]}>
        <PagesIndex.Roadmap />
      </Index.Box>
      {/* <Index.Box className="team-main" id="team-main">
        <PagesIndex.Team />
      </Index.Box> */}
      <Index.Box component={"section"} className="faq-main" id="faq-main" ref={sectionsRefs["faq-main"]}>
        <PagesIndex.Faq />
      </Index.Box>
    </>
  )
}
